<template>
  <v-container fluid grid-list-lg>
    <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList"
      :CustomerId="CustomerId"
    ></Create>
    <Edit
      :CustomerId="CustomerId"
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getList"
      :editPrice="selectedPrice"
    ></Edit>
    <v-card class="mb-3" outlined v-if="priceList.length">
      <v-card-text>
        <v-row align="center">
          <v-col class="py-0">
            <div class="subtitle-2">Remote Order</div>
            <div>Customer can only view custom price product?</div>
          </v-col>
          <v-col class="text-right shrink py-0">
            <v-switch
              @change="updateCustomer()"
              v-model="viewOnlyCustomerPrice"
              inset
              :label="viewOnlyCustomerPrice ? 'Yes' : 'No'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        title="Price List"
        @add="add"
      />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="priceList"
        :search="search"
        :loading="loading"
        :sort-by="sortBy"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr class="pointer">
            <td class="text-left">{{ item.Product.name }}</td>
            <td class="text-left" @click="editPrice">
              {{ item.price | currency }}
            </td>
            <td class="justify-end align-center text-right">
              <v-btn @click="editPrice(item)" icon>
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                @click="deletePrice(item)"
                icon
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <!-- <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item
                    v-if="checkRightStatus(18)"
                    @click="editPayee(item)"
                  >
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="checkRightStatus(10)"
                    @click="deletePrice(item)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import customerPriceService from "@/modules/Customer/CustomerPrice/service.js";
import customerService from "@/modules/Customer/service.js";
import { mapGetters } from "vuex";

const Create = () =>
  import("@/modules/Customer/CustomerPrice/components/Create.vue");
const Edit = () =>
  import("@/modules/Customer/CustomerPrice/components/Edit.vue");

export default {
  name: "customer-price-list",
  props: ["CustomerId"],
  data() {
    return {
      viewOnlyCustomerPrice: false,
      createDialog: false,
      editDialog: false,
      selectedPrice: null,
      headers: [
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "Product.name",
          sortable: true,
        },
        {
          text: "Price",
          align: "left",
          value: "Product.price",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      priceList: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    // this.getList();
    document.title = "smplsale - Price List";
  },
  watch: {
    // viewOnlyCustomerPrice(val) {
    //   // console.log("viewOnlyCustomerPrice", val);
    //   this.updateCustomer();
    // },
    CustomerId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  components: {
    Create,
    Edit,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    ...mapGetters("customer", ["getCustomer"]),
  },
  methods: {
    updateCustomer() {
      return customerService
        .update(this.CustomerId, {
          metadata: {
            viewOnlyCustomerPrice: this.viewOnlyCustomerPrice,
          },
        })
        .then(() => {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: "Customer is updated!",
            });
        });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      this.viewOnlyCustomerPrice = false;
      if (
        this.getCustomer.metadata &&
        this.getCustomer.metadata.viewOnlyCustomerPrice
      ) {
        this.viewOnlyCustomerPrice =
          this.getCustomer.metadata.viewOnlyCustomerPrice;
      }

      return customerPriceService
        .getAll({ CustomerId: this.CustomerId })
        .then((response) => {
          console.log('response', response)
          this.loading = false;
          this.priceList = response.data;
          return response;
        });
    },
    editPrice(price) {
      this.selectedPrice = price;
      this.editDialog = !this.editDialog;
    },
    deletePrice(payee) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          customerPriceService.delete(payee.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Payee has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
